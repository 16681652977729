* {
  -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
  display: none;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* spaces page */

.spacesMain {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/main-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.spacesText {
  margin: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 35%;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #3754d3;
}

/* login page */

.loginMain {
  height: 100vh;
  padding: 5rem 3rem;
  text-align: center;
}

.userIcon {
  height: 130px;
  width: 130px;
}

.textDiv {
  padding-top: 5rem;
  text-align: left;
}

.enterText {
  margin-bottom: 5px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.confirmationText {
  /* margin-bottom: 5px; */
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.inputLabel {
  /* top: 345px; */
  left: 60px;
  font-size: 10px !important;
  background-color: #fff;
  padding: 0 5px 0 5px;
  position: absolute;
  z-index: 1;
  color: #b7b4b9;
}

.inputLabelSignUp {
  left: 35px;
  font-size: 10px !important;
  background-color: #fff;
  padding: 0 5px 0 5px;
  position: absolute;
  z-index: 1;
  color: #b7b4b9;
}

.mobileInput,
.mobileInput:hover,
.mobileInput:focus {
  width: 94%;
  padding: 3%;
  margin-top: 5px;
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px;
  box-shadow: none !important;
  outline: none;
}

.nextBtnDiv {
  padding: 0rem 1rem;
}

.nextBtn,
.nextBtn:focus,
.nextBtn:hover {
  width: 100%;
  height: 2.5rem;
  margin: 5rem 0rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* OTP Page */

.otpTextDiv {
  padding-top: 5rem;
  text-align: left;
}

.enterCodeText {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 18px;
}

.weSentText {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.editIcon {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  text-align: right;
  color: #3754d3;
}

.otpInput {
  width: 30px;
  height: 30px;
  padding: 2px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
}

.didntReceiveDiv {
  padding-top: 1rem;
  text-align: center;
}

.didntReceiveText {
  font-size: 12px;
  color: #b7b4b9;
}

.resendOtpText {
  font-size: 12px;
}

.resendOtpSpan {
  font-size: 12px;
  color: #3754d3;
}

.verifyOtpBtn,
.verifyOtpBtn:focus,
.verifyOtpBtn:hover {
  width: 100%;
  height: 2.5rem;
  margin: 3rem 0rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* Sign up page */

.signUpMain {
  height: 100vh;
  padding: 2rem 1.5rem 5rem;
}

.signUpText {
  font-size: 28px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.editText {
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #3754d3;
  cursor: pointer;
}

/* .formItem {
  margin-bottom: 0;
} */

.signUpInputs,
.signUpInputs:hover,
.signUpInputs:focus {
  width: 90%;
  padding: 2%;
  margin-top: 5px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
}

.submitBtn,
.submitBtn:focus,
.submitBtn:hover {
  width: 80%;
  height: 2.5rem;
  margin: 13rem 2rem 5rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* Home Page */

.mainDiv {
  padding: 3% 1%;
  padding-bottom: 5px;
}

.greetingText {
  padding-left: 10px;
  margin-bottom: 0;
  font-size: 2em;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.userImage {
  margin-top: 12%;
  padding-right: 5px;
  float: right;
}

.whatDo {
  padding-left: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.addressCard {
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/address-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 12px;
}

.addressText {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.statusCard {
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-top: 5px;
  background-color: #9aadcf;
  border-radius: 12px;
  text-align: center;
}

.statusCardText {
  padding-top: 10px;
  margin-bottom: 0;
  font-size: 9px;
  color: #ffffff;
}

.bannerCard {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.bannerImg {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.restImg {
  width: 100%;
  height: 230px;
  border-radius: 12px;
}

.floorTabs {
  color: #2e3333;
  font-size: 14px;
}

.restCardsScroll {
  height: 90vh !important;
}

.restaurantCardDiv {
  padding: 5px;
}

.restaurantsTitle {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #3a4366;
}

.restaurantCardsList {
  margin-bottom: 5px;
  padding: 0px 5px;
}

.restaurantCard {
  width: 100%;
  /* height: 100%; */
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.restDetailsCard {
  position: relative;
  bottom: 15px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.restName {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #3a4366;
}

.restStatus {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #399e00;
  float: right;
}

.restItems {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #696969;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.restClosing {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #696969;
  float: right;
}

.menuRow {
  padding: 15px 20px 20px;
  box-shadow: 0px -5px 6px #00000029;
  height: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
}

/* Side Menu */

.drawerMainDiv {
  padding: 15px 20px;
  border-bottom: 1px solid #f2f2f2;
}

.selectedItem {
  /* height: 90%; */
  margin-right: 12px;
  padding: 20px 0px;
  background-color: #b4b9d8;
}

.spacesTextInMenu {
  color: #3754d3;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.userImageIcon {
  width: 25px;
}

.userNameInMenu {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.menuActive {
  padding-left: 20px !important;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-left: 3px solid #b4b9d8;
}

.menuIcons {
  margin-top: -4px;
  height: 5vw;
  width: 5vw;
}

.menuTextActive {
  padding-left: 36px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #3754d3;
}

.menuText {
  padding-left: 36px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

/* Restaurant Page */

.restMain {
  padding: 10px;
}

.prodCardsScroll {
  height: 93vh !important;
}

.restMainBg {
  width: 100%;
  height: 100%;
}

.leftArrowIcon {
  position: absolute;
  top: 5%;
  left: 5%;
}

.restInformCard {
  position: relative;
  border-radius: 15px;
  background-color: #ffffff;
  bottom: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}

.filtersOptionText {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.vegSwitch {
  background-color: #399e00;
  /* vertical-align: super; */
}

.heartIcon {
  padding-left: 6px;
  margin-top: -5px;
}

.filterIcon {
  padding-top: 2px;
  float: right;
}

.collapseTitle {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #000000;
}

.ProdsCount {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
}

.prodCard {
  margin-bottom: 10px;
  border-radius: 15px;
  box-shadow: 0px 3px 18px #00000029;
}

.prodImg {
  width: 105px;
  height: 105px;
  border-radius: 12px;
}

.prodName {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  color: #1d160d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.redHeart {
  padding-top: 5px;
  padding-right: 3px;
  float: right;
}

.prodDesc {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.prodPrice {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.18px;
  color: #4f403d;
}

.nutriInfo {
  font-size: 9px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.11px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  text-align: center;
}

.nutriInfoDetailsCol {
  margin-right: 10px;
  border-right: 1px solid #e5e5e5;
}

.nutriWgt {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.14px;
  color: #1d160d;
}

.nutriTitle {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.12px;
  color: #6f6f6f;
}

.addButton,
.addButton:hover,
.addButton:focus {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  color: #ffffff;
  background-color: #3754d3;
  border-radius: 4px;
  float: right;
}

.IncDecBtns {
  margin-bottom: 0px;
  float: right;
}

.minusBtn {
  padding: 2px 3px;
  background: #b7b4b9;
  border-radius: 4px;
  border: 1px solid #b7b4b9;
  color: #ffffff;
}

.plusBtn {
  padding: 2px 3px;
  background: #3754d3;
  border-radius: 4px;
  border: 1px solid #3754d3;
  color: #ffffff;
}

.healthyMenu {
  color: #1d160d;
  font-size: 14px;
  letter-spacing: 0;
}

.categoryUl {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.categoryLi {
  padding-bottom: 5px;
}

/* Cart Page */

.cartMain {
  padding: 20px 15px;
}

.cartSroll {
  height: 87vh !important;
}

.cartTitle {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.restDetailsCardInCart {
  background-image: linear-gradient(
    90deg,
    #e1e3f2 0%,
    #ededf1 50%,
    #e1e3f2 100%
  );
  border-radius: 11px;
}

.restNameInCart {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #3a4366;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.restItemsInCart {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dishImage {
  height: 120px;
  width: 120px;
  position: absolute;
  bottom: -35px;
  left: 45px;
  z-index: 1;
  border-radius: 50%;
}

.itemsInCartScroll {
  height: 53vh !important;
}

.prodInCart {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.prodQty {
  text-align: center;
}

.prodQtyInCart {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.18px;
  color: #3a4366;
}

.prodPriceInCart {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.18px;
  color: #4f403d;
  text-align: right;
}

.addonsText {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
}

.addonsList,
.addonsPrice {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.addonsList li::before {
  margin-right: 10px;
  content: "\2022";
  color: #b7b4b9;
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
}

.addonsProd {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
}

.addonsProdPrice {
  margin-bottom: 5px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
  text-align: right;
}

.billDetailsText {
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
  border-bottom: 1px solid #e5e5e5;
}

.subTotal {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.subTotalValue {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  float: right;
}

.totalLabel {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.totalValue {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  float: right;
}

.nextBtnDivInCart {
  padding: 0rem 3rem;
}

.nextBtnInCart,
.nextBtnInCart:hover,
.nextBtnInCart:focus {
  width: 100%;
  height: 2.5rem;
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* Payment Page */

.totalAmount {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #3a4366;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.totalAmountValue {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3754d3;
  float: right;
}

.paymentCardsScroll {
  height: 46vh !important;
}

.paymentCard {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 11px;
  box-shadow: 0px 3px 10px #3a436630;
}

.choosePayment {
  padding-left: 10px;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.paymentType {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.walletPaymentBal {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.paymentSelect {
  float: right;
}

.addNew {
  padding-top: 10px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3754d3;
}

.nextBtnInPayment,
.nextBtnInPayment:hover,
.nextBtnInPayment:focus {
  width: 100%;
  height: 2.5rem;
  margin-top: 11rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* .addNewPaymentModal {
  border-radius: 10px;
} */

.modalHeader {
  padding: 10px;
  background-color: #f2f2f2;
}

.modalTitle {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
  color: #3a4366;
}

.modalBody {
  padding: 10px;
}

.addNewCardFormItem {
  margin-bottom: 10px;
}

.newCardInputs,
.newCardInputs:hover,
.newCardInputs:focus {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: none;
}

.newCardInputs::placeholder {
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
  color: #000000;
}

.expiry {
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
}

.expiryInput {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: none;
  float: left;
}

.expiryInput::placeholder {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
  color: #000000;
}

.cardRemoveText {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.switchCol {
  text-align: right;
}

.removeCardSwitch {
  background-color: #d8d8d8;
}

.addCardBtnDiv {
  padding: 0rem 2rem;
}

.addCardBtn,
.addCardBtn:hover,
.addCardBtn:focus {
  width: 100%;
  height: 2.5rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* Feedback Page */

.giveFeedback {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.howWasFood {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  color: #292929;
}

.feedbackFormItem {
  margin-bottom: 10px;
}

.feedbackTextarea,
.feedbackTextarea:hover,
.feedbackTextarea:focus {
  width: 85%;
  border-radius: 7px;
  border: 1px solid #bbbbbb;
  box-shadow: none;
}

.submitFeedbackBtn,
.submitFeedbackBtn:hover,
.submitFeedbackBtn:focus {
  width: 100%;
  height: 2.5rem;
  margin-top: 5rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* Thanks Page */

.thankCard {
  background-image: url("../assets/images/thanks-bg.png");
  border: 1px solid #3754d3;
  border-radius: 12px;
  text-align: center;
}

.tickImage {
  width: 50px;
  height: 50px;
  margin-bottom: 25px;
}

.orderPlacedText {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
}

.thankYouText {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.12px;
  color: #3754d3;
  text-align: center;
}

.totalItems {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1px;
  color: #6f6f6f;
  text-align: center;
}

.paymentDetails {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.14px;
}

.paymentDetailsValues {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.14px;
  float: right;
}

.paymentConfirmation {
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.14px;
  text-align: center;
}

.yourReceipt {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.14px;
  text-align: center;
}

.qrCodeImg {
  margin-bottom: 10px;
}

.backToHomeBtn,
.backToHomeBtn:focus,
.backToHomeBtn:hover {
  width: 100%;
  height: 2.5rem;
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

/* Order History */

.orderHistoryScroll {
  height: 92vh !important;
}

.orderDetailsCard {
  /* margin-bottom: 10px; */
  border-radius: 12px;
  box-shadow: 0px 3px 18px #3a436633;
}

.OrderRestName {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
  color: #3a4366;
}

.OrderDate {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
  float: right;
}

.OrderProdName {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.16px;
  color: #1d160d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.OrderProdQty {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.16px;
  color: #1d160d;
  text-align: center;
}

.OrderProdPrice {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.16px;
  color: #1d160d;
  float: right;
}

.more {
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3a4366;
}

.reOrderBtn,
.reOrderBtn:focus,
.reOrderBtn:hover {
  width: 100%;
  background-color: #ffffff;
  color: #3754d3;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  border: 1px solid #3754d3;
  border-radius: 6px;
}

.rateOrderBtn,
.rateOrderBtn:focus,
.rateOrderBtn:hover {
  width: 100%;
  background-color: #ffffff;
  color: #1d160d;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  float: right;
}

.nextBtnDivInOrderhistory {
  padding: 1rem 3rem 0rem;
}

.nextBtnInOrderHistory,
.nextBtnInOrderHistory:hover,
.nextBtnInOrderHistory:focus {
  width: 100%;
  height: 2.5rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.active-select {
  background-color: #3754D3;
  border: 1px solid #3754D3;
  border-radius: 7px;
}

.normal-select {
  background-color: #ffffff;
  border: 1px solid #3754D3;
  border-radius: 7px;
}

.active-card-order {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
}

.normal-card-order {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3754D3;
  text-align: center;
}

.trans-history {
  margin-bottom: 10px;
  color: #1D160D;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.25px;
}

.topup-trans-card {
  margin-bottom: 10px;
  border-radius: 12px;
  box-shadow: 0px 3px 18px #3A436633;
}

.top-up {
  margin-bottom: 5px;
  color: #3A4366;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.19px;
}

.top-up-type {
  margin-bottom: 0px;
  color: #6F6F6F;
  font-size: 12px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.top-up-date {
  margin-bottom: 5px;
  color: #6F6F6F;
  font-size: 13px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  text-align: right;
}

.top-up-amt {
  margin-bottom: 0px;
  color: #1D160D;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.16px;
  text-align: right;
}

/* Addons Page */

.itemDetailsCard {
  position: relative;
  bottom: 15px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemName {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3a4366;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vegImg {
  margin-top: 5px;
  float: right;
}

.itemPrice {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #696969;
}

.itemRating {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #696969;
  float: right;
}

.starImg {
  width: 12px;
  height: 12px;
  vertical-align: text-top;
}

.addOnsChoose {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #2e3333;
  /* border-bottom: 3px solid #E37F66; */
}

.bottomLine {
  height: 1px;
  margin-right: 88%;
  margin-top: 0;
  margin-bottom: 15px;
  background-color: #e37f66;
  border-radius: 50px;
  border: 1px solid #e37f66;
}

.addOnsScroll {
  height: 38vh !important;
}

.addOnItem {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.addOnItemPrice {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.continueBtnDivInCart {
  padding: 0rem 4rem;
}

.continueBtn,
.continueBtn:hover,
.continueBtn:focus {
  width: 100%;
  height: 2.5rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.requiredText {
  font-size: 14px;
  color: #686b78;
  font-weight: 300;
}

/* Favourites Page */

.your-favourites {
  padding-top: 15px;
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3a4366;
}

.favouritesScroll {
  height: 77vh !important;
}

.favProdCard {
  border-radius: 15px;
  box-shadow: 0px 3px 18px #00000029;
}

.orderDetailsScroll {
  height: 20vh !important;
}

/* Addons Custom Card */

.addOnCustomCard {
  border-radius: 20px;
  background-color: #f2f2f2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}

.your-previous {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  color: #000000;
}

.addons-selection {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  color: #6f6f6f;
}

.repeatCustomBtn,
.repeatCustomBtn:focus,
.repeatCustomBtn:hover {
  width: 100%;
  background-color: #ffffff;
  color: #3754d3;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  border: 1px solid #3754d3;
  border-radius: 6px;
}

.chooseBtn,
.chooseBtn:focus,
.chooseBtn:hover {
  width: 100%;
  background-color: #ffffff;
  color: #1d160d;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.17px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  float: right;
}

/* Desktop UI */

.headerRow {
  width: 100%;
  padding: 10px 20px;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}

.spacesTextDesktop {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3754d3;
}

.addressTextDesk {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3a4366;
}

.lorem-ipsum {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #595959;
}

.footerTitle {
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3a4366;
}

.footerMenu {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #575e65;
}

.emailInput,
.emailInput:hover,
.emailInput:focus {
  width: 100%;
  padding: 4%;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  box-shadow: none;
}

.emailInput::placeholder {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #bbbbbb;
}

.copyRight {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1.72px;
  color: #000000;
}

.socialImgs {
  width: 70%;
  height: 70%;
}

.restBg {
  padding: 8% 15% 3%;
  width: 100%;
  /* height: 100%; */
  background-image: url("../assets/images/rest-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.4; */
}

.prodCardDesk {
  margin-bottom: 10px;
  border-radius: 15px;
}

.restImgDesk {
  width: 200px;
  height: 180px;
  border-radius: 12px;
}

.redNameDesk {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
}

.redStatusDesk {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #e2e2e2;
  float: right;
}

.redDescDesk {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #e2e2e2;
}

.redDescDeskRight {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #e2e2e2;
  float: right;
}

.vegSwitchDesk {
  background-color: #ffffff;
  vertical-align: super;
}

.prodDivDesk {
  padding: 1% 15% 10%;
  background-color: #f2f1f6;
}

.prodFooter {
  background-color: #f2f1f6;
}

.minusBtnDesk {
  padding: 5px 8px;
  background: #b7b4b9;
  border-radius: 4px;
  border: 1px solid #b7b4b9;
  color: #ffffff;
}

.plusBtnDesk {
  padding: 5px 8px;
  background: #3754d3;
  border-radius: 4px;
  border: 1px solid #3754d3;
  color: #ffffff;
}

.vegImgDesk {
  margin-top: 10px;
  float: right;
}

.addOnDivDesk {
  padding: 2% 35% 10%;
  background-color: #f2f1f6;
}

.cartDesk {
  padding: 6% 15% 10%;
  background-color: #f2f1f6;
}

.cartTitleDesk {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.clearAll {
  margin-bottom: 0;
  /* font-size: 13px; */
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  cursor: pointer;
}

.cartItemsDiv {
  padding: 30px 15px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 18px;
}

.addonsProdPriceDesk {
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6f6f6f;
  text-align: left;
}

.subTotalDesk {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
}

.subTotalValueDesk {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  float: right;
}

.nextBtnDivInCartDesk {
  padding: 0rem 19rem;
}

.thanksPageDesk {
  padding: 2% 35%;
  height: 100vh;
  background-color: #00000099;
}

.thanksMainDiv {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 6px;
}

.orderHistoryPageDesk {
  padding: 6% 17% 6%;
  background-color: #f2f1f6;
}

.orderHistDesk {
  margin-top: 25px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 6px;
}

.orderDetailsCardDesk {
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}

.rateOrderDesk {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 6px;
}

.feedbackTextareaDesk,
.feedbackTextareaDesk:hover,
.feedbackTextareaDesk:focus {
  width: 50%;
  border-radius: 7px;
  border: 1px solid #bbbbbb;
  box-shadow: none;
}

.submitFeedbackBtnDesk,
.submitFeedbackBtnDesk:hover,
.submitFeedbackBtnDesk:focus {
  width: 100%;
  height: 2.5rem;
  margin-top: 2rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.loginFormColDesk {
  padding: 2% 9% 2%;
  background-color: #3754d3;
}

.loginAnimColDesk {
  padding: 5% 7% 5%;
  background-color: #f2f1f6;
}

.loginMainDesk {
  /* height: 100vh; */
  padding: 2rem 3rem;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
}

.welcomeText {
  /* margin-bottom: 10px; */
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
}

.userIconDesk {
  height: 100px;
  width: 100px;
}

.textDivDesk {
  padding-top: 2rem;
  text-align: left;
}

.inputLabelDesk {
  left: 175px;
  font-size: 10px !important;
  background-color: #fff;
  padding: 0 5px 0 5px;
  position: absolute;
  z-index: 1;
  color: #b7b4b9;
}

.nextBtnDivDesk {
  padding: 0rem 0rem;
}

.nextBtnDesk,
.nextBtnDesk:focus,
.nextBtnDesk:hover {
  width: 100%;
  height: 2.5rem;
  margin: 4rem 0rem 0rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.verifyOtpBtnDesk,
.verifyOtpBtnDesk:focus,
.verifyOtpBtnDesk:hover {
  width: 100%;
  height: 2.5rem;
  margin: 1rem 0rem 0rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.mobileInputDesk,
.mobileInputDesk:hover,
.mobileInputDesk:focus {
  width: 100%;
  padding: 3%;
  margin-top: 5px;
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px;
  box-shadow: none !important;
  outline: none;
}

.otpTextDiv {
  padding-top: 2rem;
  text-align: left;
}

.signUpFormColDesk {
  padding: 5% 9% 2%;
  background-color: #3754d3;
}

.signUpMainDesk {
  /* height: 100vh; */
  padding: 2rem 3rem;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
}

.signUpTextDesk {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.inputLabelSignUpDesk {
  left: 180px;
  font-size: 10px !important;
  background-color: #fff;
  padding: 0 5px 0 5px;
  position: absolute;
  z-index: 1;
  color: #b7b4b9;
}

.signUpInputsDesk,
.signUpInputsDesk:hover,
.signUpInputsDesk:focus {
  width: 100%;
  padding: 2%;
  margin-top: 5px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  box-shadow: none;
}

.submitBtnDesk,
.submitBtnDesk:focus,
.submitBtnDesk:hover {
  width: 100%;
  height: 2.5rem;
  margin: 2rem 0rem 0rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.accountMainDesk {
  /* height: 100vh; */
  padding: 2rem 3rem;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
}

.accountInputs,
.accountInputs:hover,
.accountInputs:focus {
  width: 50%;
  padding: 2%;
  margin-top: 5px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  box-shadow: none;
}

.saveBtnDiv {
  padding: 0rem 28rem 0rem 4rem;
}

.saveBtn,
.saveBtn:focus,
.saveBtn:hover {
  width: 100%;
  height: 2.5rem;
  margin: 1rem 0rem 0rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.inputLabelAccount {
  left: 290px;
  font-size: 10px !important;
  background-color: #fff;
  padding: 0 5px 0 5px;
  position: absolute;
  z-index: 1;
  color: #b7b4b9;
}

.prodCardsScrollDesk {
  height: 100vh !important;
}

/* Side Menu Styles */

.spacesTextInMenuDesk {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.userNameInMenuDesk {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.logoutMenu {
  position: absolute;
  bottom: 0;
  left: 15px;
}

@media only screen and (min-width: 601px) {
  .desktop-hide {
    display: none;
  }

  .statusCard {
    padding: 30px 15px;
  }

  .mainDiv {
    padding: 4% 1% 3%;
    padding-bottom: 0px;
  }

  .restaurantCardDiv {
    padding: 5px;
    padding-top: 20px;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    background-color: transparent !important;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent !important;
  }

  .restNameInCart {
    margin-bottom: 10px;
  }

  .dishImage {
    left: 290px;
  }

  .favProdCard {
    border-radius: 15px;
    box-shadow: none;
  }
}

@media only screen and (max-width: 600px) {
  .mobile-hide {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }
}

/* Wallet Screen */

.wallet-card {
  background-image: url("../assets/images/wallet-bg.png");
  border: 1px solid #3754D3;
  border-radius: 11px;
}

.available-balance {
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
}

.wallet-bal {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  text-align: center;
  letter-spacing: 0px;
}

.topup-card {
  margin-top: 10px;
  box-shadow: 0px 3px 18px #3A436633;
  border-radius: 11px;
}

.topup-wallet {
  color: #292929;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
}

.topup-input, .topup-input:hover, .topup-input:focus {
  margin-bottom: 10px;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  box-shadow: none;
}

.topup-input::placeholder {
  color: #E5E5E5;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
}

.recommended {
  color: #6F6F6F;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
}

.topup-activecard {
  background-color: #3754D3;
  border: 1px solid #3754D3;
  border-radius: 4px;
}

.topup-value-active {
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.17px;
  text-align: center;
}

.topup-value-card {
  border: 1px solid #3754D3;
  border-radius: 4px;
}

.topup-value {
  margin-bottom: 0px;
  color: #3754D3;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.17px;
  text-align: center;
}

.topUpBtnDiv {
  padding: 0rem 3rem;
}

.topUpBtn,
.topUpBtn:hover,
.topUpBtn:focus {
  width: 100%;
  height: 2.5rem;
  margin-top: 13rem;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff6339;
  border: 1px solid #ff6339;
  border-radius: 30px;
}

.add-money {
  padding: 2px;
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.13px;
  text-align: center;
}

/* Loyalty Screen */

.loyalty-card {
  background-image: url("../assets/images/loyalty-bg.png");
  border: 1px solid #3754D3;
  border-radius: 11px;
}

.loaylty-points {
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
}

.available-points {
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
}

.active-select-loyalty {
  background-color: #4870F2;
  border: 1px solid #4870F2;
  border-radius: 7px;
}

.normal-select-loyalty {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 7px;
}

.active-card-loyalty {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
}

.normal-card-loyalty {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3754D3;
  text-align: center;
}

.offer-name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.22px;
  color: #1D160D;
}

.offer-validity {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #696969;
}

.offer-code {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #000000;
}

.super {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #3754D3;
}

.you-earned {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.22px;
  color: #1D160D;
}

.earned-amt {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #6F6F6F;
}

.earned-points {
  padding: 14px;
  background-color: #F0F0F0;
  border-radius: 50%;
  text-align: center;
}

.earned-points-text {
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.16px;
  color: #000000;
}
